export const Styles = (styled, Proto, Props) => {

return styled.div`
width: 100%;
height: 100%;
padding: 0;
margin: 0;
// background: antiquewhite;

#App {
width: 100%;
height: 100%;
min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 0;
overflow-y: scroll;

  @media (max-width: 768px) {
  margin-top: -5%;
  }

._Input {
width: auto;
height: 15%;
padding: 2rem;
margin: 2rem;
color: #444;
text-align: center;
border-radius: 50px;
font-size: 3rem;
border: 2px solid rgba(0, 0, 0, 0.1);
font-family: "Ginora Sans";
}

._Container {
position: relative;
aspect-ratio: 1/1;
width: 500px;
max-width: 100%;
// height: 85%;
}

._Dispositivo {
position: absolute;
width: 77%;
height: 77%;
top: 11.5%;
left: 11.5%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
// overflow: hidden;
border: 5px solid rgba(55, 55, 55, 0.1);
// box-shadow: 0 0 3px rgba(55, 55, 55, 0.3);
border-radius: 100%;
// padding: 1rem;
z-index: 11;
}

._Onda {
position: relative;
width: 100%;
height: 100%;
border-radius: 50%;
// background: rgb(50, 50, 50, 0.1);
z-index: 10;
display: none;
}

.onda-circle {
position: relative;
width: 100%;
height: 100%;
border-radius: 50%;
// background: #e5e5e5;
// box-shadow: 0 0 1px rgba(55, 55, 55, 0.3);
border: 2px dashed rgba(55, 55, 55, 0.1);
}

.punto {
list-style-type: none;
position: absolute;
width: 80px;
height: 80px;
border-radius: 50%;
transform: translate(-50%, -50%);

  .onda {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: #fff;
  border-radius: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  background: antiquewhite;

  // box-shadow: 0 0 1px rgba(55, 55, 55, 0.3);
  // border: 1px dashed #ccc;
  transition: all 0.5s;
  // border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  cursor: pointer;

    i { 
    opacity: 0.5;
    font-size: 4rem;
    // color: #555934;
    }

    span {
    margin-top: 1rem;
    // display: none;
    font-family: "Ginora Sans";
    }

    &:hover { 
    color: #81dbe3;
    }

    &.Active {
    i { opacity: 1; }
    background: #81dbe3;
    color: #eee;
    }

  }

}

}

  .circle-container {
  perspective: 1000px;
  width: 100%;
  height: 100%;
  position: relative;
  }

  .circle {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1.7s;
  }

  .circle-front, .circle-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 50%;
  backface-visibility: hidden;
  }

  .circle-front {
  background: blue;
  z-index: 2;
  }

  .circle-back {
  z-index: 1;
  transform: rotateY(180deg);
  }

  .circle.flipped {
  transform: rotateY(180deg);
  }

  .circle.flipped .circle-front {
  z-index: 1;
  }

  .circle.flipped .circle-back {
  z-index: 2;
  }


`;

}