//----------------------------------------------------------------------------------------

  import { Styled } from './Style/Style_Admin_Onda_Detail.js';

//----------------------------------------------------------------------------------------
  export const Admin_Onda_Detail = ({ Props }) => {
//----------------------------------------------------------------------------------------

  Props.URI = `${Public.ENV.URL}/Apps/Parts/Main/Admin/Onda/Detail`;
  const { useState, useEffect, useRef } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Styled(Public.Libs.styled, {}, Props.URI);
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  const history = RouterDOM.useHistory();

//█████████████████████████████████████████████████████████████████████████████████████
//=====================================================================================

  const theOnda = useRef(undefined);

  const [Deleted, SetDeleted] = useState("Borrar");

  const [Published, SetPublished] = useState("Publicar");
  const [PublishedTwitter, SetPublishedTwitter] = useState(false);
  const [PublishedInstagram, SetPublishedInstagram] = useState(false);
  
//=====================================================================================

  const [Item, SetItem] = useState(null);
      
  useEffect(() => { Get_Item(); }, []);

  const Get_Item = async () => {
    Public.DB.Read({
      URI: `Admin/Onda`,
      Items: [id], 
      Interface: {ID: true, Text: true, Networks: true, Files: true},
      Callback: (items) => { SetItem(items[0]); }
    });
  }

  useEffect(() => { if(!Item) { return }
    
    console.info("Item:", Item);

    // Twitter
    let Network_Twitter = Item.Networks.find((n) => n.Type === 'Twitter');
    SetPublishedTwitter(Network_Twitter?.Published);
    console.log(Network_Twitter)

    // Instagram
    let Network_Instagram = Item.Networks.find((n) => n.Type === 'Instagram');
    SetPublishedInstagram(Network_Instagram?.Published);

  }, [Item]);

//=====================================================================================

  const Delete = async () => {
    
    Public.DB.Drop({
      URI: `Admin/Onda`,
      Items: [id], 
      Callback: (Response) => { 
        console.info("🗑️ Deleted!", Response); 
        SetDeleted(html`<i className="icofont-ui-check icofont-1x"></i>`);
        history.push(`/admin/onda`);
      }
    });
    
  }

  const Publish = (Network) => {
    
    let formData = new FormData();
    formData.append('ID', Item.ID);
    formData.append('Text', Item.Text);
    formData.append('Network', Network);
    
    fetch(`${Public.ENV.URL}/admin/onda/publish`, { 
      method: 'POST',
      body: formData,
    })
    .then(fetch => fetch.json())
    .then(res => { 
      
      console.info("✅ Published!", res); 
      SetPublished(html`<i className="icofont-ui-check icofont-1x"></i>`);
      Get_Item();

    });
    
  }


//=====================================================================================

return (html`
<${Style}>

  <div className="_List">

    <div className="_Navbar">
      <h2 className="_Title">Onda</h2>
      <${RouterDOM.Link} to="/Admin/Onda" className="_Button">
        <i className="icofont-arrow-left icofont-1x"></i>
      <//>
    </div>

    ${Item && html`
    <div className="Onda_Detail" key="Admin_Onda_Detail">

      <div ref=${theOnda} className="_Item">
        ${Item.Files[0] && html`
          <img src=${Public.Scripts.Blob_To_Img_URL(Item.Files[0]?.Blob)} />
        `}
        <p className="_Text">${Item.Text}</p>
      </div>

      <div className="_Buttons">
        <div className="_Button _Delete" onClick=${() => { Delete() }}>${Deleted}</div>
        <${RouterDOM.Link} to="/Admin/Onda/Edit/${id}">
          <div className="_Button _Edit">Editar</div>
        <//>
      </div>
    
    </div>
    `}  

  </div>

  <div className="_Sidebar">

    <div className="_Navbar">
      <h2 className="_Title">Redes</h2>
    </div>
    
    <div className="_Buttons _Horizontal">
      <div className="_Button _Twitter" onClick=${() => { Publish('Twitter') }}>
        <i className="icofont-twitter icofont-1x"></i> Twitter
        ${PublishedTwitter ? 
        html`<i className="icofont-ui-check icofont-1x"></i>` : html`<i className="icofont-ui-close icofont-1x"></i>`}
      </div>
      <div className="_Button _Instagram" onClick=${() => { Publish('Instagram') }}>
        <i className="icofont-instagram icofont-1x"></i> Instagram
        ${PublishedInstagram ? 
          html`<i className="icofont-ui-check icofont-1x"></i>` : html`<i className="icofont-ui-close icofont-1x"></i>`}
      </div>
    </div>

  </div>

<//>
`);
}