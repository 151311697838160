export const Style = (styled, Proto, Props) => {

const Style = styled.div`
width: 100%;
height: 100%;
color: #333;
background-color: antiquewhite;
display: flex;
flex-direction: column;

  ._Container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4rem 6rem 4rem 6rem;
  }

  ._Sidebar {
  flex: 0 40%;
  height: 100%;
  display: flex;
  text-align: center;
  padding-left: 4rem;

    & > a {
    width: 100%;
    height: 100%;
    }

  }
  
  ._Main {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  // background: aliceblue; 
  // overflow-y: auto;
  // padding: 4rem 6rem;

    & > a {
    width: 100%;
    height: 30%;      
    }

    @media only screen and (max-width: 50rem) {
      
    }

  }

  a {
  width: auto;
  // aspect-ratio: 4.2;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 2rem;
  // margin-bottom: 2rem;

    & > ._Title {
    z-index: 12;
    color: #eee;
    font-family: 'Ginora Sans';
    position: relative;
    transition: opacity 0.5s;
    }

    .BannerImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    }

    .ShadowLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: opacity 0.5s;
    }

    &:hover {
    cursor: pointer;

      .ShadowLayer {
      opacity: 0;
      }

      ._Title {
      opacity: 0;
      }

    }

  }

`;

return Style;

}