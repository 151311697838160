//----------------------------------------------------------------------------------------

  import { TREE } from '../TREE.js';
  import { Composition_1 } from './Compositions/Sounds_Of_Stone.js';

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  export class TREE_SOUND extends TREE { constructor (MAIA) { super(MAIA);
//----------------------------------------------------------------------------------------

  this.MAIA = MAIA;

  this.Audio = undefined;
  this.Tone = undefined;
  
  this.Sounds = [];

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Init () {
//----------------------------------------------------------------------------------------

  this.Audio = Public.Scripts.Audio;
  this.Tone =  this.Audio.Tone;

  this.Synths = this.Audio.Synths;
  this.Collection = { One: Composition_1 };

}
//----------------------------------------------------------------------------------------
  Run () {
//----------------------------------------------------------------------------------------

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Play_File ({ID, URI, Track, Loop, Volume}) {
//----------------------------------------------------------------------------------------
    
  let Sound = this.Sounds.find(Sound => Sound.ID === ID);
  if(Sound) {
    this.MAIA.TREE.SOUND.Play(Sound.ID);
    return;
  }

  if(!URI) { console.error("No URI provided"); return; }
  if(!ID) { ID = "Sound_File_ID"; }
  if(!Track) { Track = "Track_File_ID"; }

  Public.DB.File({
    URI: URI, 
    Callback: (Blob) => {
      // let File = Items[0].Files[0];
      let Sound = this.MAIA.TREE.SOUND.Create({ID, Props: { Track, Files: [{Blob: Blob}], Loop, Volume}}, () => {});
      if(Sound?.ID) { this.MAIA.TREE.SOUND.Play(Sound.ID); }
      else { 
        // console.error(Sound, ID, URI, Blob) 
      }
    }
  });

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Create (Evt, Callback) { // {ID, Track, File, Loop, Volume, Fade}
//----------------------------------------------------------------------------------------

  Evt.File = Evt?.Props?.Files[0];

  let Sound = this.Sounds.find(Sound => Sound.ID === Evt.ID);
  // console.log("this.Sounds?", this.Sounds);

  if(Sound) {
    // this.MAIA.TREE.SOUND.Play(Sound.ID);
    return Callback(Sound, true);
    
  }
  else {
    // console.log("Sound not finded, creating", Evt, Sound)
  }

  // Checks
  if(!Evt.File) { console.error("File not defined", Evt); return false; }
  if(!Evt.ID) { console.error("ID not defined", Evt); return false; }
  if(!Evt.File.Blob) { console.error("File Blob not defined", Evt); return false; }

  // Create URL
  try { Evt.File.URL = URL.createObjectURL(Evt.File.Blob); } 
  catch (error) { 
    console.error(`Error on: URL.createObjectURL()`, Evt); 
  }
  
  // 🐺 Howl
  Evt.Howl = new Howl({
    src: [Evt.File.URL],
    format: ['mp3'],
    loop: Evt.Props.Loop || false,
    volume: Evt.Props.Volume || 1,
    // fade: [0, Volume || 1, Fade || 0.2]
  });

  // Track
  Evt.Props.Track = Evt.Props.Track;
  
  // 💾 Save
  Evt.Active = false;
  this.Sounds.push(Evt);

  return Callback(Evt, false);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Find ({Sound_ID, Track_ID}) {
//----------------------------------------------------------------------------------------
  
  if(this.Sounds.length <= 0) { return false; }

  if (Track_ID) {
    return this.Sounds.filter((sound) => sound.Props.Track && sound.Props.Track.ID === Track_ID);
  }
  else if(Sound_ID) { 
    return this.Sounds.find((sound) => sound.ID === Sound_ID); 
  }
  else { 
    console.error("Can't find Sound", Sound_ID, Track_ID, this.Sounds); 
  }

}
//----------------------------------------------------------------------------------------
  Play (Sound_ID) {
//----------------------------------------------------------------------------------------

  let Sound = this.Find({Sound_ID: Sound_ID});
  
  if (Sound?.Howl?.play()) {
    Sound.Active = true;
    return true;
  }
  else {

  }

}
//----------------------------------------------------------------------------------------
  Pause ({Sound_ID, Track_ID}) {
//----------------------------------------------------------------------------------------

  let Sounds = [];
  
  if (Track_ID) { Sounds = this.Find({Track_ID: Track_ID}); }
  else if(Sound_ID) { Sounds.push = this.Find({Sound_ID: Sound_ID}); }
  
  Sounds.map((Sound => {

    if(Sound?.Howl?.pause()) {
      Sound.Active = false;
      return true;
    }
    
  }))
  
}
//----------------------------------------------------------------------------------------
  Remove ({Sound_ID, Track_ID}) {
//----------------------------------------------------------------------------------------

  // console.log(this.Sounds)

  if (Track_ID) {
    let Sounds_Track = this.Sounds.filter((sound) => sound.Props.Track?.ID === Track_ID);
    Sounds_Track.map((Sound) => {
      if(Sound?.Howl) {
        Sound.Howl.stop();
        Sound.Howl.unload(); // ?
        this.Sounds = this.Sounds.filter((sound) => sound.ID !== Sound_ID);
      }  
    })
  }
  else if(Sound_ID) { 
    const Sound = this.Find({Sound_ID: Sound_ID});
    if(Sound?.Howl) {
      Active.Howl.stop();
      Active.Howl.unload(); // ?
      this.Sounds = this.Sounds.filter((sound) => sound.ID !== Sound_ID);
    }  
  }
  else { 
    console.error("Can't find Sound", Sound_ID, Track_ID, this.Sounds); 
  }

}
//----------------------------------------------------------------------------------------
  Stop_Track (Track) {
//----------------------------------------------------------------------------------------

  if(this.Sounds.length <= 0) { return; }

  let activeSound = this.Sounds.find(active => active?.Track?.ID === Track);
  
  if (activeSound) {

    // Fade out
    activeSound.Howl.fade(activeSound.Volume, 0, 1000, activeSound.Sound_ID);

    // Remove
    this.Stop(activeSound.ID);

  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Restore (ID) {
//----------------------------------------------------------------------------------------

  let Active = this.Sounds.find((active) => active.ID === ID);
  if (Active) { Active.Paused = false; Active.Howl.play(); }

}
//----------------------------------------------------------------------------------------
  async Fade_In (Sound) {
//----------------------------------------------------------------------------------------

  // El período durante el cual se producirá el fade in (por ejemplo, 1 segundo)
  const fadeInDuration = 1000;
  var soundId = Sound.play();
  Sound.fade(0, Volume || 1, fadeInDuration, soundId);

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//----------------------------------------------------------------------------------------
  Reset () {
//----------------------------------------------------------------------------------------

  Howler.stop();
  
  this.Sounds.forEach((active) => {
    if (active.Howl) { active.Howl.unload(); }
  });

  this.Sounds = []; 

}
//----------------------------------------------------------------------------------------
}