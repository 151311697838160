import { Style } from './Style/Style_Items_Chips_Detail.js';

export const Items_Chips_Detail = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Items/Chips/Detail`;
  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const {id} = RouterDOM.useParams();
  const history = RouterDOM.useHistory();
  const Part_Parallax = Public.Scripts.Choose_Part('Parallax');  

//████████████████████████████████████████████████████████████████████████████████████

  const [Item, SetItem] = useState(null);
  const [Images, SetImages] = useState([
    {URL: `${URI}/Media/Parallax/0.png`},
    {URL: `${URI}/Media/Parallax/1.png`},
    {URL: `${URI}/Media/Parallax/2.png`}
  ]);

  useEffect(() => {
    Get_Chip();
  }, []);

  const Get_Chip = async () => {
    Public.DB.Read({
      URI: `Items/Chips`, 
      Interface: { ID: true, Title: true, Tags: true, Description: true, Files: false },
      Items: [id], 
      Callback: (items) => {  
        SetItem(items[0]);
      }
    });
  }

  const Close = () => {
    history.goBack().goBack();
    Public.Scripts.Audio.Sound({ID: 'Pop_Close'});
  }

  const Open_Game = () => {
    Public.Scripts.Audio.Sound({ID: 'Pop_Open'});
    Public.Scripts.Notify.Send({
      Title: "Demo", Description: `¡Iniciando!`, Image: "Gamepad", Time: 5000
    });
  }

  const Open_Trailer = () => {
    Public.Scripts.Audio.Sound({ID: 'Pop_Open'});
    Public.Scripts.Notify.Send({
      Title: "Próximamente", Description: `¡Disponible próximamente!`, Image: "Video", Time: 5000
    });
  }

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------


//████████████████████████████████████████████████████████████████████████████████████

// URI_Poster=${Public.Scripts.Blob_To_Img_URL(Item?.Files[1]?.Blob)}

return (html`
<${Styled}>

${Item && html`
<div className="_Items_Chip_Detail">

  <div className="_Poster">
    <${Suspense} fallback=${html`<div></div>`}>
      ${html`<${Part_Parallax} Props=${{Images: Images}}/>`}
    <//>
  </div>

  <div className="_Main">

    <div className="_Navbar">
      <${RouterDOM.Link} to="/intro" onClick=${()=> { }}>
        <div className="_Close">
          <i className="icofont-close icofont-1x"></i>
        </div>
      <//>
    </div>

    <div className="_Content">
      
      <div className="_Text">
        <h2 className="_Title">${Item && Item.Title}</h2>
        <p className="_Description" dangerouslySetInnerHTML=${{ __html: Item?.Description }}></p>
        <!--<div className="_Tags">${Item?.Tags.map((Tag, i) => {
          return html`<div className="_Tag" key="Items_Chips_Detail_Tag_${i}">${Tag}</div>`
        })}
        </div>-->
      </div>  
      
      <div className="Buttons">
        
        <${RouterDOM.Link} to="/maia" onClick=${()=> { }}>
        <div className="Button_Play" onClick=${()=> { Open_Game() }}>
          <i className="icofont-game-controller icofont-1x"></i>Demo
        </div>
        <//>

        <div className="Button_Play _Inactive" onClick=${()=> { Open_Trailer() }}>
          <i className="icofont-ui-video-play icofont-1x"></i>Tráiler
        </div>

      </div>

    </div>

    <div></div>
    <!--<div className="_Footer">
      <h3 className="_Title">Con la contribución de:</h3>
      <img src="${Public.ENV.URL}/Apps/Items/Chips/Detail/Media/Murcia.png" alt="Ayuntamiento de Murcia"/>
    </div>-->

  </div>
</div>
`}

<//>
`);


}

// <div className="Cover" style=${{"backgroundImage": `url(${Public.ENV.URL}/Public/Media/Database/Chips/${Item.ID}/${Cover})` }}>
