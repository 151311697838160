//----------------------------------------------------------------------------------------

import { Style } from './Style/Style_App_Intro.js';

//----------------------------------------------------------------------------------------

export const App_Intro = ({ Props }) => {

  let URI = `${Public.ENV.URL}/Apps/Intro`;
  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Styled = Public.Styles.Styled(Style, {});
  const RouterDOM = Public.Libs.RouterDOM;
  const Part_Parallax = Public.Scripts.Choose_Part('Parallax');  

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// State
//----------------------------------------------------------------------------------------

  const theMain = useRef(null);

  const [Loaded, SetLoaded] = useState(false);
  const [Images, SetImages] = useState([
    {URL: undefined},
    {URL: `${URI}/Media/Parallax/1.png`},
    {URL: `${URI}/Media/Parallax/2.png`},
  ]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Hooks
//----------------------------------------------------------------------------------------

  useEffect(() => {
    SetLoaded(true);
  }, []);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Styled}>

<div className="Navbar">
  <h2 className="_Title">inicio</h2>
</div>

<div className="_Container">

  <div className="_Main" ref=${theMain}>

    <${RouterDOM.Link} to="/Items/Chips/1" key="Intro_Aura_Adventure">
      <div className="BannerImage" style=${{ backgroundImage: `url(${URI}/Media/Aventura.png)` }}></div>
      <div className="ShadowLayer"></div>
      <h2 className="_Title">Aura Adventure</h2>
    <//>

    <${RouterDOM.Link} to="/Community" key="Intro_Community">
      <div className="BannerImage" style=${{ backgroundImage: `url(${URI}/Media/Banner/Community.png)` }}></div>
      <div className="ShadowLayer"></div>
      <h2 className="_Title">Comunidad</h2>
    <//>

    <${RouterDOM.Link} to="/about" key="Intro_Blog">
      <div className="BannerImage" style=${{ backgroundImage: `url(${URI}/Media/Banner/Blog.png)` }}></div>
      <div className="ShadowLayer"></div>
      <h2 className="_Title">Blog</h2>
    <//>

  </div>

  <div className="_Sidebar">
    
    <${RouterDOM.Link} to="/roadmap" key="Intro_Roadmap">
      <div className="BannerImage" style=${{ backgroundImage: `url(${URI}/Media/Banner/Map.png)` }}></div>
      <div className="ShadowLayer"></div>
      <h2 className="_Title">Crowdfunding</h2>
    <//>

  </div>

</div>

<//>
`);

};