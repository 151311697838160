
  // import { GameOfLife } from "../Scripts/GameOfLife/GameOfLife.js";
  import { Digi } from '../Digi.js';
import { Styles } from '../Styles/Styles_Game.js';

//----------------------------------------------------------------------------------------
  export const Game = ({ Egg }) => {
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Libs
//----------------------------------------------------------------------------------------

  const { useState, useEffect, useRef, Suspense } = Public.Libs.React;
  const html = Public.Libs.html;
  const Style = Public.Styles.Styled(Styles, {});

//████████████████████████████████████████████████████████████████████████████████████

  // Mode
  const [Mode, SetMode] = useState('Empty');

  // Dialog
  const [IsDialogVisible, SetDialogVisible] = useState(false);

  useEffect(() => {
    if(Egg) { 
      // console.log("🥚", Egg);
      SetDialogVisible(true);
    }    
  }, [Egg]);

  const Dialogs = {
    Empty: [ "¡Hola! ¡Haz clic aquí!", "¡Visita nuestro stand!", "Allí comienza todo." ],
    Digitori: [ 
      "¡Hola!", 
      "¡Te estaba esperando!", 
      "¡Soy tu Digitori!", 
      "Estoy dentro de este Huevo Digital.", 
      "¡Tengo una idea!", 
      "¡Si acudes al Stand de initori, podré materializarme!",
      "¡Habla con el Staff para crear tu Digitori!",
      "¡Y juntos, podríamos competir en el Torneo Digitori!",
      "¡Vamos allá!"
    ],
    Digitama: [ 
      "¡Hola!", 
      "¡Te estaba esperando!", 
      "¡Soy tu Digitori!", 
      "Estoy dentro de este Huevo Digital.", 
      "¡Tengo una idea!", 
      "¡Si acudes al Stand de initori, podré materializarme!",
      "¡Habla con el Staff para crear tu Digitori!",
      "¡Y juntos, podríamos competir en el Torneo Digitori!",
      "¡Vamos allá!"
    ],
    Aura: [ 
      "¡Hola! ¡Pulsa aquí!", 
      "¡Soy Aura! Vivo en el mundo digital de Maia.",
      "Pero me gustaría ver el Mundo Exterior.",
      "¡Quisiera ver todos los Stands de la Feria!",
      "¡Y tener una foto de recuerdo!",
      "¿Ves ese mapa de ahí?",
      "Los Stands en los que ya he estado están señalados.",
      "¡Quisiera ver lugares nuevos!",
      "¿Te animas a la aventura?",
      "¡Si me ayudas, te daré un Bonus de Luz!",
      "Primero, escoge un Stand que todavía no esté señalado en el mapa.",
      "Después, coge una figurita y dirígete allí.",
      "¡Y por último, haz una foto!",
      "¡Vamos a la aventura!",
    ],
    Eroda: [ 
      "...", 
      "Parece que me has encontrado.", 
      "¿Ves esas runas en la pared?", 
      "Son Runas Aúricas, el primer lenguaje que crearon los maia...",
      "... su significado se perdió hace mucho tiempo.",
      "Solamente la luz de los maia puede revelar su significado.",
      "¿Ves esos artefactos? Contienen la luz de los maia.",
      "Si proyectas su luz en las Runas, podrías ver algo...",
      "Lamentablemente, yo no puedo ver su luz.",
      "Por eso necesito tu ayuda.",
      "Coge 1 Tarjeta y escribe en el reverso el significado.",
      "A cambio, te daré un Bonus de Sombra.",
    ],
    Fuzz: [ 
      "... zzzz ...", 
      "¡Oh! Me has despertado.", 
      "No pasa nada. ¡Te estaba esperando!",
      "¿Ves esas tarjetas de ahí?",
      "¡Coge 1 Tarjeta y dibuja algo bonito!",
      "¡Lo que se te ocurra!",
      "Si pones tu corazón, saldrá algo genial.",
      "¡Y para darte las gracias, te daré un Bonus de Alma!",
      "¿Ves la Taza de Girasoles?",
      "Puedes coger lo que necesites para dibujar.",
      "¡Buena suerte!"
    ],
  };
  
  // Chars
  const [phraseIndex, setPhraseIndex] = useState(0); 
  const [charIndex, setCharIndex] = useState(0);
  const [text, setText] = useState('');
  
  const [Frame, SetFrame] = useState(0);
  const [Animating, SetAnimating] = useState(false);

  let Aura = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0,
    0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  ];

  let Eroda = [
    [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1,
    1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1,
    1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
  ],
  [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
    1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
    ]
  ];

  let Fuzz = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    [
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0,
    1, 1, 1, 0, 0, 0, 0, 0, 1, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1,
    1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1,
    0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0,
    0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
  ];

  let Digitama = [
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 1, 1, 1, 1, 1, 1, 1, 0, 0,
    0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]  
  ];
  
  let Digitori = Digitama;

  let Empty = Digitama;
 
  // Egg
  const [Character, SetCharacter] = useState(Egg?.Character);


  // Grid
  const [Group, SetGroup] = useState(Empty);
  const [Grid, SetGrid] = useState(Empty[0]);
  const [Index, SetIndex] = useState(0);

//----------------------------------------------------------------------------------------

  useEffect(() => { if(!Egg) { return; }
         if(Character === 'Aura') { SetGroup(Aura); SetMode('Aura'); }
    else if(Character === 'Eroda') { SetGroup(Eroda); SetMode('Eroda'); }
    else if(Character === 'Fuzz') { SetGroup(Fuzz); SetMode('Fuzz'); }
    else if(Character === 'Digitori') { SetGroup(Digitori); SetMode('Digitori'); }
    else if(Character === 'Digitama') { SetGroup(Digitama); SetMode('Digitama'); }
    else { SetGroup('Empty') }
  }, [Character]);

  useEffect(() => {
    
  }, [Group]);

//----------------------------------------------------------------------------------------

  useEffect(() => { 
    
    if(!Group || Group.length <= 1) { return; }

    const intervalId = setInterval(() => {
      
      // console.log(Animating, Egg, Frame)
      if(!Animating && !Egg) { return; }
      if(Egg && Frame === 0) { SetFrame(Frame + 1); return; }

      SetIndex(prevIndex => {

        SetFrame(Frame + 1);
        
        if(!Egg) {
          Public.Scripts.Audio.Play({URI: `${Public.ENV.URL}/Public/Media/Sounds/Egg/Init/Lifegame/Lifegame_${Frame+1}.mp3`});
        }
                
        const newIndex = (prevIndex + 1) % Group.length;
        SetGrid(Group[newIndex]);
        return newIndex;
      });

    }, 1000);
    return () => clearInterval(intervalId);

  }, [Group, Animating, Frame]);

  useEffect(() => { 
    
   if(Frame >= 4) { 
    SetFrame(0);
    SetAnimating(false);
   }

  }, [Frame]);

//=====================================================================================

  // Game of Life
  const [Count, SetCount] = useState(0);  

  useEffect(() => { 
    // GameOfLife(); 
  });

  useEffect(() => { // Count active Bits

    if(!Grid) { return; }
    const filteredArray = Grid.filter((element) => element === 1);
    const count = filteredArray.length;
    SetCount(count);

  }, [Grid]);

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Scripts
//----------------------------------------------------------------------------------------

  const [PlayingSound, SetPlayingSound] = useState(null);
  const [SoundIndex, SetSoundIndex] = useState(1);

  const Sound_Clic = async () => {

    let FileSound;
    if(Mode === 'Digitama') {
      console.log(SoundIndex)
      FileSound = `${Public.ENV.URL}/Public/Media/Sounds/Egg/Digitama/Lifegame_${SoundIndex}.mp3`;
      let SoundIndexNext = SoundIndex + 1; if(SoundIndexNext > 4) { SoundIndexNext = 1;}
      SetSoundIndex(SoundIndexNext);
    }
    else {
      FileSound = Egg 
      ? `${Public.ENV.URL}/Public/Media/Sounds/Egg/${Egg.Character}/OK.mp3` 
      : `${Public.ENV.URL}/Public/Media/Sounds/Egg/Torii.mp3`;
    }

    if (PlayingSound) { PlayingSound.stop(); }
  
    const newSound = await Public.Scripts.Audio.Play({ URI: FileSound, Volume: 0.25 }, () => {
      SetPlayingSound(null);
    });
  
    SetPlayingSound(newSound);

  };

  const Play_Note = (coords) => {
    
    return; 
    function getNoteFromIndex(x, y) {
      const center = { x: 4, y: 4 };
      const distance = Math.sqrt((x - center.x) ** 2 + (y - center.y) ** 2);
      const octave = Math.floor((distance / 4) + 4);
      const note = ["C", "D", "E", "F", "G", "A", "B"][x % 7];
      return `${note}${octave}`;
    }

  }

  const Toggle_Bit = (coords) => {
    
    let newGrid = Grid.slice();
    let index = ((coords.y-1) * 11) + (coords.x - 1);

    if(newGrid[index] === 0) { newGrid[index] = 1; }
    else 
    if(newGrid[index] === 1) { newGrid[index] = 0; } 
    
    SetGrid(newGrid);

  }

  const Handle_Bit = (index) => {
    
    // Note
    function getCoordinates (i) {
      const x = ((i) % 11) + 1;
      const y = (Math.floor((i) / 11)) +1;
      return { x, y };
    }

    let coords = getCoordinates(index);
    
    Play_Note(coords);

    // Toggle
    Toggle_Bit(coords);

  }

// Dialog
//----------------------------------------------------------------------------------------

  useEffect(() => {
    
    if(!Dialogs[Mode]) { console.log(`Mode ${Mode} not defined`); }

    // Si el texto se ha completado, no hay necesidad de continuar.
    if (charIndex >= Dialogs[Mode][phraseIndex].length) return;
    
    // Función que agrega las letras una por una.
    const typeLetter = () => {
      if (charIndex < Dialogs[Mode][phraseIndex].length) {
        setText(prevText => prevText + Dialogs[Mode][phraseIndex][charIndex]);
        setCharIndex(prevCharIndex => prevCharIndex + 1);
      }
    };
    
    // Usando setTimeout en lugar de setInterval.
    const timeoutId = setTimeout(typeLetter, 42); // 100ms entre cada letra.

    // Limpiar el timeout cuando el componente se desmonta o se cambia la frase.
    return () => clearTimeout(timeoutId);

  }, [charIndex, phraseIndex]);

  
  const handleDialogClick = () => {

    // console.log("click")
    SetAnimating(!Animating);

    Sound_Clic();

    if (charIndex < Dialogs[Mode][phraseIndex].length) {
        // Si el texto aún se está escribiendo, muestra el texto completo.
        setText(Dialogs[Mode][phraseIndex]);
        setCharIndex(Dialogs[Mode][phraseIndex].length);
    } else if (phraseIndex < Dialogs[Mode].length - 1) {
        // Si no es la última frase, avanza a la siguiente.
        setPhraseIndex(prevIndex => prevIndex + 1);
        setText(''); // Resetear el texto.
        setCharIndex(0); // Empezar desde el primer carácter.
    } else {
        SetDialogVisible(false); // Ocultar el diálogo si es la última frase.
    }

  };

//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
// Render
//----------------------------------------------------------------------------------------

return (html`
<${Style}>

${IsDialogVisible ? html`
  <div className="_Dialog">
      ${text}
  </div>
` : null}

<div id="Digitama" className="_Square" onClick=${() => { handleDialogClick()} }>

  <div id="Grid">
    
    ${Grid && Grid.map((Cell, index) => {

      if(Cell === 0) { return html`
      <div className="Cell Void" onClick=${(event) => { Handle_Bit(index); }} key="Cell_${index}"/>`; }
      else
      if(Cell === 1) { return html`
      <div className="Cell Full" onClick=${(event) => { Handle_Bit(index); }} key="Cell_${index}"/>`; }

    })}

  </div>

</div>

<//>
`);

//----------------------------------------------------------------------------------------
};