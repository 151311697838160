//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  export class CORE_DEBUG { constructor (MAIA) {
//-----------------------------------------------------------------------------

  this.MAIA = MAIA;
  this.ID = "DEBUG";
 
  this.Active = false;
  this.Items = [];

  this.Performance = undefined;
  this.Count = 0;

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Init () {  
//-----------------------------------------------------------------------------
  this.Active = false;
}
//-----------------------------------------------------------------------------
  Close () {
//-----------------------------------------------------------------------------
  this.Active = false;
}
//-----------------------------------------------------------------------------
  Toggle () {
//-----------------------------------------------------------------------------
  this.Active = !this.Active;
}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Run () {
//-----------------------------------------------------------------------------

  if(!this.Active) { return false; }

  // Actualizar Performance cada segundo
  this.Count++;
  if(this.Count >= 60) { 
    this.Performance = performance.memory;
    this.Count = 0
  };
  
  this.Set_Default();
  this.Show();

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Add (string) {
//-----------------------------------------------------------------------------
  this.Items.push(string);
}
//-----------------------------------------------------------------------------
  Set_Default () {
//-----------------------------------------------------------------------------

  if(this.Active) {

    this.Add(``);
    this.Add("🖥️ " + this.MAIA.CORE.TIME.framesLastSecond + " FPS/s");
    this.Add(`👤 User: ` + this.MAIA.ENV.User.ID);
    // this.Add("🔺 Delta: " + this.MAIA.CORE.TIME.Delta);
    // this.Add("🔺 Frame: " + this.MAIA.CORE.TIME.frameCount);
    
    let Player = this.MAIA.TREE.ENT.Find('Aura');
    this.Add("Move: " + Player.Find('Aura_Move').Acceleration);
    this.Add("z: " + Player.Find('Aura_Cubit').POS.Coords.z);
    this.Add("Action: " + Player.Find('Aura_Gravity').State.Action);

    /* 
    this.Add(``);
    this.Add("🎥 Origin: " + this.MAIA.TREE.CAMERA.Origin.w + " , " + this.MAIA.TREE.CAMERA.Origin.h);
    this.Add("🎥 Move: " + Math.round(this.MAIA.TREE.CAMERA.Move.w) + " , " + Math.round(this.MAIA.TREE.CAMERA.Move.h));
    this.Add("🎥 Focus: " + Math.round(this.MAIA.TREE.CAMERA.Focus.w) + " , " + Math.round(this.MAIA.TREE.CAMERA.Focus.h));

    if (this.Performance) {
      this.Add(``);
      this.Add(`💾 Heap Total: ${ (this.Performance.jsHeapSizeLimit / 1024 / 1024).toFixed(2) } MB`);
      this.Add(`💾 Heap Used: ${ (this.Performance.usedJSHeapSize / 1024 / 1024).toFixed(2) } MB`);
      this.Add(`💾 Heap Allocated: ${ (this.Performance.totalJSHeapSize / 1024 / 1024).toFixed(2) } MB`);
    } */  

    // this.Add("Target: " + this.MAIA.CLIENT.DOM.State.Panel.Target);
    // this.Add("Active: " + this.MAIA.CLIENT.DOM.State.Panel.Active);
    // this.Add("⌛" + this.MAIA.CORE.TIME.currentMiliSecond);
    
    // this.Add("🖱️ Left: " + this.MAIA.TREE.CLIENT.INPUT.State.Cursor.Left.Active);
    // this.Add("🖱️ Right: " + this.MAIA.TREE.CLIENT.INPUT.State.Cursor.Right.Active);
    // this.Add(this.MAIA.TREE.MODE.ACTIVE.CubitHigher.ID);

    // Aura
    // this.Add("Action: " + Player.Find('Aura_Jump').State.Action);
    // this.Add("Velocity: " + Player.Find('Aura_Jump').State.Velocity);
    // this.Add("Levitation: " + Player.Find('Aura_Gravity').Levitatio.Now);

    /*
    // Gamepad
    this.Add("A: " + this.MAIA.TREE.CLIENT.INPUT.State.Gamepad.A.Active);
    this.Add("B: " + this.MAIA.TREE.CLIENT.INPUT.State.Gamepad.B.Active);
    this.Add("X: " + this.MAIA.TREE.CLIENT.INPUT.State.Gamepad.X.Active);
    this.Add("Y: " + this.MAIA.TREE.CLIENT.INPUT.State.Gamepad.Y.Active);
    this.Add("Start: " + this.MAIA.TREE.CLIENT.INPUT.State.Gamepad.Start.Active);

    this.Add("👾 Ents: " + this.MAIA.TREE.ENT.Ents.length);
    this.Add("💥 Evts: " + this.MAIA.TREE.EVT.Evts.length);
    this.Add("📜 Signals: " + this.MAIA.TREE.SIG.Sigs.length);
    this.Add("🧊 Cubits: " + this.MAIA.TREE.CUBIT.Cubits.length);
    this.Add("🔊 Sounds: " + this.MAIA.TREE.SOUND.Actives.length);
    */
   
  }

}
//▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄▄
//-----------------------------------------------------------------------------
  Show () {
//-----------------------------------------------------------------------------

  let Width = 260;
  let Pos_Right = this.MAIA.TREE.RENDER.Canvas.width - Width - 40;
  let Pos_Top = 30;

  var ctx = this.MAIA.TREE.RENDER.Ctx;
  ctx.save();

  this.Items.map((element, i) => {

    // Rectangle
    ctx.fillStyle = "#333";
    ctx.fillRect(Pos_Right, (30 * i) + 100, Width, Pos_Top);

    // Text
    ctx.font = "18px Arial";
    ctx.textAlign = 'left';
    ctx.fillStyle = "Aqua";
    ctx.fillText(element, Pos_Right + 5, (Pos_Top * i) + 120);

  });

  ctx.restore();

  // Reset array.
  this.Items = [];

}
//-----------------------------------------------------------------------------
}